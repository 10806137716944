import React, { useState } from 'react';
import styled from 'styled-components';
import TransitionLink from 'gatsby-plugin-transition-link';

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  
  @media (min-width: 950px) {
    display: block;
    padding: 0;
    position: ${({ absolute }) => absolute ? 'absolute' : 'relative'};
    bottom: 0;
  }
`;

const StyledLink = styled(TransitionLink)`
  display: inline-block;
  margin: 0 0 35px;
  background-color: transparent;
  transition: background-color 0.15s ease-in-out;
  border-radius: 24px;
  padding: 5px 20px 7px;
  background-color: ${({ active }) => (active ? 'var(--color-8080)' : 'transparent')};
  pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
  cursor: pointer;
  line-height: 20px;

  .body-color &,
  .body-color-no-anim & {
    background-color: ${({ active }) => (active ? 'var(--secondary)' : 'transparent')};
  }

  @media (min-width: 700px) {
    margin: 0 30px 25px;

    &:hover {
      background-color: var(--color-8080);
    }

    .body-color &:hover,
    .body-color-no-anim &:hover {
      background-color: var(--secondary);
    }
  }
`;

const MailLink = styled(StyledLink)`
  width: 170px;

  @media (min-width: 950px) {
    margin: 0;
  }
`;

const Footer = ({ mail, invert, active, isMobile, absolute }) => {
  const [mailText, setMailText] = useState(mail);

  const handleMailClick = async (event) => {
    if (!navigator.clipboard) {
      // Clipboard API not available:
      const copyToClipboard = (textToCopy) => {
        var textArea;

        const isOS = () => {
          //can use a better detection logic here
          return navigator.userAgent.match(/ipad|iphone/i);
        };

        const createTextArea = (text) => {
          textArea = document.createElement('textArea');
          textArea.readOnly = true;
          textArea.contentEditable = true;
          textArea.value = text;
          document.body.appendChild(textArea);
        };

        const selectText = () => {
          var range, selection;

          if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
          } else {
            textArea.select();
          }
        };

        const copyTo = () => {
          document.execCommand('copy');
          document.body.removeChild(textArea);
        };

        createTextArea(textToCopy);
        selectText();
        copyTo();

        setMailText('Copied');
        setTimeout(() => {
          setMailText(mail);
        }, 2000);
      };

      copyToClipboard(event.target.innerText);

      return;
    }
    const text = event.target.innerText;
    try {
      await navigator.clipboard.writeText(text);
      setMailText('Copied');
      setTimeout(() => {
        setMailText(mail);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  return (
    <Wrapper absolute={absolute}>
      <StyledLink
        active={active === 'about' ? 'true' : undefined}
        invert={invert}
        to={'/about'}
        preventScrollJump={true}
      >
        About
      </StyledLink>
      <MailLink as='div' invert={invert} onClick={(e) => handleMailClick(e)}>
        {mailText}
      </MailLink>
      <StyledLink preventScrollJump={true} invert={invert} to='/work' active={active === 'work'}>
        Work
      </StyledLink>
    </Wrapper>
  );
};

export default Footer;
